import { type FC } from 'react';

import { type IconProps } from './IconType';

const MessageChat: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5325 10.9334C4.5325 6.22053 8.35301 2.40002 13.0658 2.40002C17.7787 2.40002 21.5992 6.22053 21.5992 10.9334C21.5992 12.16 21.3403 13.3263 20.8743 14.3804L21.6008 19.4659L17.2426 18.3763C16.008 19.0707 14.5832 19.4667 13.0658 19.4667M2.40018 16.2667C2.40018 17.0334 2.56194 17.7623 2.85319 18.4211L2.39917 21.5995L5.12276 20.9186C5.89432 21.3525 6.78476 21.6 7.73301 21.6C10.6782 21.6 13.0658 19.2122 13.0658 16.2667C13.0658 13.3212 10.6782 10.9334 7.73301 10.9334C4.78777 10.9334 2.40018 13.3212 2.40018 16.2667Z"
        stroke={strokeColor}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MessageChat;
