import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

import {
  AccountBalanceOutlined,
  ContactlessOutlined,
  InsertChartOutlined,
  ReceiptLongOutlined,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ArrowSwitchHorizontal,
  Bank,
  BarGroup,
  FileCheck,
  FileEdit,
  FolderOpen,
  MessageChat,
  ReceiptLines,
  Settings,
} from '@/assets/icons';
import { hide } from '@/assets/images';
import { useAppDispatch, useAppSelector, useAppTranslation } from '@/hooks';
import { type Chat, OperationsService } from '@/services';
import ChatService from '@/services/ChatService';
import {
  setKpiValidationReceipt,
  setKpiValidationTransaction,
  setUnreadChatsCounter,
} from '@/store/actions/action-creators';
import { APP_VERSION } from '@/utils/constants';
import { cls } from '@/utils/funcs';

import { LockedBackdrop } from './LockedBackdrop';

interface SidebarProps {
  locked?: boolean;
}

const Sidebar: FC<SidebarProps> = ({ locked = false }) => {
  const { t } = useAppTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const validatedReceiptNumber = useAppSelector(
    (store) => store.global.validatedReceiptNumber
  );
  const validatedTransactionNumber = useAppSelector(
    (store) => store.global.validatedTransactionNumber
  );
  const planInfo = useAppSelector((store) => store.global.planInfo);
  const unreadChatNumber = useAppSelector(
    (store) => store.global.unreadChatNumber
  );
  const connectedUser = useAppSelector((store) => store.global.connectedUser);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [selectedItem, setSelectedItem] = useState('Performances');
  const location = useLocation();

  const displayKpiValidation = (kpi: any) => {
    return (kpi ?? 0) > 0 && kpi;
  };

  const navItems = useMemo(
    () => [
      {
        type: 'item',
        name: 'Performances',
        icon: (strokColor: string) => <BarGroup strokeColor={strokColor} />,
        href: '/dashboard/performances',
        permission: 'ALL',
      },

      {
        type: 'item',
        name: 'Receipts',
        icon: (strokColor: string) => <ReceiptLines strokeColor={strokColor} />,
        notificationCount: displayKpiValidation(validatedReceiptNumber),
        href: '/dashboard/receipts',
        permission: 'ALL',
      },

      {
        type: 'item',
        name: 'Transactions',
        icon: (strokColor: string) => (
          <ArrowSwitchHorizontal strokeColor={strokColor} />
        ),
        notificationCount: displayKpiValidation(validatedTransactionNumber),
        href: '/dashboard/transactions',
        disabledOnDrive: true,
        permission: 'ALL',
      },
      {
        type: 'item',
        name: 'Statements',
        icon: (strokColor: string) => <Bank strokeColor={strokColor} />,
        href: '/dashboard/statements',
        permission: 'ALL',
      },

      {
        type: 'item',
        name: 'Billing',
        icon: (strokColor: string) => <ReceiptLines strokeColor={strokColor} />,
        href: '/dashboard/billing',
        permission: 'ALL',
      },
      {
        type: 'item',
        name: 'Documents',
        icon: (strokColor: string) => <FolderOpen strokeColor={strokColor} />,
        href: '/dashboard/documents',
        permission: 'ALL',
      },

      {
        type: 'Line',
      },

      {
        type: 'item',
        name: 'Chats',
        notificationCount: unreadChatNumber,
        icon: (strokColor: string) => <MessageChat strokeColor={strokColor} />,
        href: '/dashboard/chats',
        permission: 'ALL',
      },

      {
        type: 'item',
        name: 'Declarations',
        icon: (strokColor: string) => <FileCheck strokeColor={strokColor} />,
        href: '/declarations',
        permission: 'ALL',
      },

      {
        type: 'item',
        name: t('Book entries'),
        icon: (strokColor: string) => <FileEdit strokeColor={strokColor} />,
        href: '/dashboard/accounting/book-entries',
        permission: 'ACCOUNTANT',
      },
      {
        type: 'Line',
      },
      {
        type: 'item',
        name: t('Settings'),
        icon: (strokColor: string) => <Settings strokeColor={strokColor} />,
        href: '/settings',
        permission: 'ALL',
      },
    ],
    [validatedTransactionNumber, validatedReceiptNumber]
  );

  const navItemsMobile = useMemo(
    () => [
      {
        type: 'item',
        name: 'Performances',
        icon: <InsertChartOutlined />,
        href: '/dashboard/performances',
      },
      {
        type: 'item',
        name: 'Receipts',
        icon: <ReceiptLongOutlined />,
        notificationCount: displayKpiValidation(validatedReceiptNumber),
        href: '/dashboard/receipts',
      },
      {
        type: 'item',
        name: 'Transactions',
        icon: <ContactlessOutlined />,
        notificationCount: displayKpiValidation(validatedTransactionNumber),
        href: '/dashboard/transactions',
        disabledOnDrive: true,
      },
      {
        type: 'item',
        name: 'Statements',
        icon: <AccountBalanceOutlined />,
        href: '/dashboard/statements',
      },
    ],
    [validatedTransactionNumber, validatedReceiptNumber]
  );

  const toggleSidebarExpansion = () =>
    setIsSidebarExpanded((isSidebarExpanded) => !isSidebarExpanded);

  useEffect(() => {
    if (location.pathname.includes('settings')) {
      setSelectedItem('/settings');
    } else if (location.pathname.includes('declarations')) {
      setSelectedItem('/declarations');
    } else {
      setSelectedItem(location.pathname);
    }
  }, [location.pathname]);

  useEffect(() => {
    OperationsService.getKpiValidation().then((res) => {
      dispatch(setKpiValidationReceipt(res.data?.validatedReceiptNumber));
      dispatch(
        setKpiValidationTransaction(res.data?.validatedTransactionNumber)
      );
    });
    ChatService.getAllChats().then((res) => {
      dispatch(
        setUnreadChatsCounter(
          res?.data?.filter((chat: Chat) => {
            if (
              !connectedUser.roles.every((item: string) =>
                chat.readByRoles?.includes(item)
              ) &&
              chat.messages.length > 0 &&
              chat.messages[chat.messages.length - 1].who != connectedUser.id
            ) {
              return chat;
            }
          }).length
        )
      );
    });
  }, [dispatch]);

  return (
    <div className="relative">
      {locked && <LockedBackdrop />}
      <div
        className={cls(
          'hidden min-[600px]:flex min-[600px]:flex-col min-[600px]:relative duration-500 ',
          isSidebarExpanded ? 'min-w-[250px]' : 'min-w-[60px]'
        )}
      >
        <div className="h-[100%] fixed overflow-y-auto scroll-bar-style overflow-x-hidden bg-first pt-[55px] border-r-[1.5px]	">
          <div
            className={cls(
              ' h-full flex duration-500 flex-col transition-width',
              isSidebarExpanded ? 'w-[250px]' : 'w-[55px]'
            )}
          >
            <div className="grow items-center justify-center flex bg-red">
              <div className="w-[80%] h-full pt-2 pb-[3vh] bg flex flex-col gap-1">
                {navItems.map(
                  (
                    {
                      type,
                      name,
                      notificationCount,
                      icon,
                      href,
                      disabledOnDrive,
                      permission,
                    },
                    idx
                  ) =>
                    type === 'item' &&
                    (permission === 'ALL' ||
                      connectedUser.roles.includes(permission)) ? (
                      <div key={href}>
                        <Tooltip placement="right" arrow title={t(name)}>
                          <a
                            href={href}
                            className={cls(
                              href === selectedItem && 'bg-[#E6EEFE]',
                              isSidebarExpanded ? 'py-2 px-4' : 'gap-0 p-2',
                              !planInfo?.features?.OCR &&
                                disabledOnDrive &&
                                'pointer-events-none opacity-50 select-none',
                              'flex gap-4 rounded-lg items-center cursor-pointer hover:bg-[#eef1fc] dark:hover:bg-[#1b1d2c] transition-colors duration-200'
                            )}
                            onClick={(e) => {
                              e.preventDefault();
                              navigate(href ?? '');
                            }}
                          >
                            <div>
                              {icon &&
                                icon(
                                  href === selectedItem ? '#0075E7' : '#8D98AF'
                                )}
                            </div>
                            {isSidebarExpanded ? (
                              <div className="flex justify-between w-full gap-2 items-center truncate">
                                <p
                                  className={cls(
                                    href === selectedItem
                                      ? 'color-blue'
                                      : 'color-black opacity-60',
                                    'font-normal truncate'
                                  )}
                                >
                                  {t(name)}
                                </p>
                                {!!notificationCount && (
                                  <p className="text-xs bg-[#FDF9EC] text-[#7A5802] font-medium px-2 py-1 rounded-full">
                                    {notificationCount}
                                  </p>
                                )}
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </a>
                        </Tooltip>
                      </div>
                    ) : (
                      <hr key={idx} className="my-2" />
                    )
                )}
              </div>
            </div>

            <div className="h-[400px] transition-all duration-300	w-[90%] ml-auto mr-auto justify-end items-center flex flex-col cursor-pointer">
              <div className="flex justify-center pt-6 pb-6">
                <button onClick={toggleSidebarExpansion}>
                  <img
                    src={hide}
                    alt="Hide"
                    style={{ rotate: isSidebarExpanded ? undefined : '180deg' }}
                  />
                </button>
              </div>

              <p className="text-center pt-4 text-black pb-4 text-xs">
                V {APP_VERSION}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* 600px (mobile) Navbar */}
      <div className="min-[600px]:hidden max-[600px]:block bg-first fixed bottom-0 w-full z-40">
        <div className="flex  justify-between p-2">
          {navItemsMobile.map(
            ({ name, icon, notificationCount, href, disabledOnDrive }) => (
              <a
                key={href}
                href={href}
                className={cls(
                  name === selectedItem && 'bg-blue-low-opacity',
                  !planInfo?.features?.OCR &&
                    disabledOnDrive &&
                    'pointer-events-none opacity-50 select-none',
                  'flex flex-col rounded-lg items-center pb-1 gap-1'
                )}
              >
                <div
                  className={`pr-2 pl-3 ${
                    name === selectedItem
                      ? 'stroke-[#0075E7]'
                      : 'stroke-[#989A9E]'
                  }`}
                >
                  {icon}{' '}
                  <h3 className="bg-blue text-xs color-button-text px-1 ml-3 rounded-full	absolute top-1 ">
                    {notificationCount}
                  </h3>
                </div>
                <p
                  className={`${
                    name === selectedItem
                      ? 'color-blue'
                      : 'color-black opacity-60'
                  }   font-semibold w-full text-xs`}
                >
                  {t(name)}
                </p>
              </a>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
