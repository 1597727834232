import { lazy } from 'react';

import { Route } from 'react-router-dom';

import { DeclarationsLayout } from '@/components/layouts';
import { AuthGuard, RolesGuard, SubscriptionGuard } from '@/lib/guards';
import { Role } from '@/lib/guards/RolesGuard';

import type { RouteType } from '.';

const AddedValueTax = lazy(() => {
  const AddedValueTaxPath = import('@/pages/Declarations/AddedValueTax');

  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(AddedValueTaxPath), 500);
  });
});

const AddDeclaration = lazy(() => {
  const AddDeclarationPath = import('@/pages/Declarations/AddDeclarations');

  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(AddDeclarationPath), 500);
  });
});

const ConsumerRights = lazy(() => {
  const ConsumerRightsPath = import('@/pages/Declarations/ConsumerRights');

  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(ConsumerRightsPath), 500);
  });
});

const Foprolos = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/Declarations/Foprolos')), 500);
  });
});

const InsuranceTaxes = lazy(() => {
  const InsuranceTaxesPath = import('@/pages/Declarations/InsuranceTaxes');
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(InsuranceTaxesPath), 500);
  });
});

const LocalTaxes = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/Declarations/LocalTaxes')), 500);
  });
});

const OtherTaxes = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/Declarations/OtherTaxes')), 500);
  });
});

const StampDuty = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/Declarations/StampDuty')), 500);
  });
});

const VocationalTrainingTax = lazy(() => {
  const VocationalTrainingTaxPath = import(
    '@/pages/Declarations/VocationalTrainingTax'
  );
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(VocationalTrainingTaxPath), 500);
  });
});

const Withholding = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/Declarations/Withholding')), 500);
  });
});

const Declarations = lazy(() => {
  return new Promise((resolve) => {
    // @ts-expect-error
    setTimeout(() => resolve(import('@/pages/Declarations/Declarations')), 500);
  });
});

const outletElements = [
  { element: <Declarations />, href: '' },
  {
    element: <AddDeclaration />,
    href: 'initialization',
  },
  {
    element: <AddedValueTax />,
    href: 'added-value-tax',
  },
  {
    element: <ConsumerRights />,
    href: 'consumer-rights',
  },
  {
    element: <Foprolos />,
    href: 'foprolos',
  },
  {
    element: <InsuranceTaxes />,
    href: 'insurance-taxes',
  },
  {
    element: <LocalTaxes />,
    href: 'local-taxes',
  },
  {
    element: <OtherTaxes />,
    href: 'other-taxes',
  },
  {
    element: <StampDuty />,
    href: 'stamp-duty',
  },
  {
    element: <VocationalTrainingTax />,
    href: 'vocational-training-tax',
  },
  {
    element: <Withholding />,
    href: 'withholding',
  },
];

const routes: RouteType[] = [
  {
    children: (
      <>
        {outletElements.map(({ element, href }, idx) => (
          <Route key={idx} element={element} path={href} />
        ))}
      </>
    ),
    element: (
      <AuthGuard>
        <RolesGuard allowedRoles={[Role.OWNER, Role.USER, Role.ACCOUNTANT]}>
          <SubscriptionGuard>
            <DeclarationsLayout />
          </SubscriptionGuard>
        </RolesGuard>
      </AuthGuard>
    ),
    path: 'declarations',
  },
];

export default routes;
