import { type FC } from 'react';

import { type IconProps } from './IconType';

const FileEdit: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5573 21.5574H4.75729C3.4318 21.5574 2.35729 20.4828 2.3573 19.1574L2.35739 4.75741C2.3574 3.43193 3.43192 2.35742 4.75739 2.35742H15.5577C16.8831 2.35742 17.9577 3.43194 17.9577 4.75742V9.55742M13.1575 18.2484L18.2487 13.1573L21.6428 16.5514L16.5516 21.6426H13.1575V18.2484Z"
        stroke={strokeColor}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FileEdit;
