import { type FC } from 'react';

import { type IconProps } from './IconType';

const FolderOpen: FC<IconProps> = ({
  width = 24,
  height = 24,
  strokeWidth = 2,
  strokeColor = '#5C6B7A',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.579 10.4842V7.44213C19.579 6.88985 19.1313 6.44213 18.579 6.44213H11.909C11.6438 6.44213 11.3894 6.33678 11.2019 6.14924L9.7666 4.71397C9.57906 4.52644 9.32471 4.42108 9.05949 4.42108H3.40002C2.84774 4.42108 2.40002 4.8688 2.40002 5.42108V18.579C2.40002 19.1313 2.84774 19.579 3.40002 19.579H4.57619C5.04862 19.579 5.4316 19.196 5.4316 18.7236V18.7236C5.4316 18.621 5.45005 18.5193 5.48607 18.4232L8.21985 11.1331C8.36622 10.7428 8.73934 10.4842 9.15618 10.4842H20.2126C20.8952 10.4842 21.3771 11.1529 21.1613 11.8005L18.7964 18.8952C18.6603 19.3035 18.2781 19.579 17.8477 19.579H4.42108"
        stroke={strokeColor}
        stroke-width={strokeWidth}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FolderOpen;
